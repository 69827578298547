import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138')
];

export const server_loads = [2,4,5,18,19,20,21,22,23,24,25,27,13,14,17,9];

export const dictionary = {
		"/(base)/(marketing)": [~53,[2,11],[3]],
		"/(base)/(app)/account": [33,[2,4,5],[3]],
		"/(base)/(app)/account/holds": [~34,[2,4,5],[3]],
		"/(base)/(app)/account/notifications": [~35,[2,4,5],[3]],
		"/(base)/(app)/account/payment": [~36,[2,4,5],[3]],
		"/(base)/(app)/account/profile": [~37,[2,4,5],[3]],
		"/(base)/admin": [~68,[2,18],[3]],
		"/(base)/admin/ai": [69,[2,18],[3]],
		"/(base)/admin/farms": [~70,[2,18],[3]],
		"/(base)/admin/farms/create": [~90,[2,18],[3]],
		"/(base)/admin/farms/[farmId]": [~71,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/blackout-periods": [~72,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/coupons": [~73,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/coupons/create": [~75,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/coupons/[couponId]": [~74,[2,18,20],[3]],
		"/(base)/admin/farms/[farmId]/delivery": [~76,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/delivery/[deliveryOptionId]": [~77,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/organization": [~78,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/pickup": [~79,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/pickup/create": [~81,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/pickup/[pickupOptionId]": [~80,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/plans": [~82,[2,18,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]": [83,[2,18,21],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/members": [~84,[2,18,21],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/pickup-options": [~85,[2,18,21],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/pickup-options/[csaPlanPickupOptionId]": [~86,[2,18,21],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/settings": [~87,[2,18,21],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/waitlist": [~88,[2,18,21],[3]],
		"/(base)/admin/farms/[farmId]/waitlists": [~89,[2,18,19],[3]],
		"/(base)/admin/tools/algolia": [~91,[2,18],[3]],
		"/(base)/admin/tools/calendar": [92,[2,18],[3]],
		"/(base)/admin/users": [~93,[2,18],[3]],
		"/(base)/admin/users/create": [~97,[2,18],[3]],
		"/(base)/admin/users/[userId]": [~94,[2,18,22],[3]],
		"/(base)/admin/users/[userId]/holds": [~95,[2,18,22],[3]],
		"/(base)/admin/users/[userId]/subscriptions": [~96,[2,18,22],[3]],
		"/(base)/(auth)/auth/redirect": [~46,[2,10],[3]],
		"/(base)/(auth)/auth/verify": [~47,[2,10],[3]],
		"/(base)/csa/[slug]": [~98,[2,23],[3]],
		"/(base)/csa/[slug]/add-ons": [~99,[2,23],[3]],
		"/(base)/csa/[slug]/confirm": [~100,[2,23],[3]],
		"/(base)/csa/[slug]/plans": [~101,[2,23],[3]],
		"/(base)/csa/[slug]/share": [~102,[2,23,24],[3]],
		"/(base)/csa/[slug]/share/delivery": [~103,[2,23,24],[3]],
		"/(base)/csa/[slug]/share/pickup": [~104,[2,23,24],[3]],
		"/(base)/csa/[slug]/share/pickup/waitlist/success": [~105,[2,23,24],[3]],
		"/(base)/csa/[slug]/share/sign-up": [~106,[2,23,24],[3]],
		"/(base)/csa/[slug]/sign-up": [~107,[2,23],[3]],
		"/(base)/dashboard": [~108,[2,25],[3]],
		"/(base)/dashboard/csa/fulfillment/route/[routeId]": [~112,[2,25,28],[3]],
		"/(base)/dashboard/csa/fulfillment/[fulfillmentDate]": [~109,[2,25,27],[3]],
		"/(base)/dashboard/csa/fulfillment/[fulfillmentDate]/orders": [~110,[2,25,27],[3]],
		"/(base)/dashboard/csa/fulfillment/[fulfillmentDate]/routes": [~111,[2,25,27],[3]],
		"/(base)/dashboard/customers": [~113,[2,25,29],[3]],
		"/(base)/dashboard/customers/retail": [~115,[2,25,29],[3]],
		"/(base)/dashboard/customers/wholesale": [~116,[2,25,29],[3]],
		"/(base)/dashboard/customers/wholesale/[orgId]": [~117,[2,25],[3]],
		"/(base)/dashboard/customers/[userId]": [~114,[2,25],[3]],
		"/(base)/dashboard/orders/csa/[orderId]": [~120,[2,25],[3]],
		"/(base)/dashboard/orders/csa/[fulfillmentDate]/[locationId]/[userId]": [~119,[2,25],[3]],
		"/(base)/dashboard/orders/[orderId]": [~118,[2,25,30],[3]],
		"/(base)/dashboard/products": [~121,[2,25],[3]],
		"/(base)/dashboard/products/create": [~123,[2,25],[3]],
		"/(base)/dashboard/products/[farmProductId]": [~122,[2,25],[3]],
		"/(base)/dashboard/reports": [124,[2,25],[3]],
		"/(base)/dashboard/reports/past-due": [~125,[2,25],[3]],
		"/(base)/dashboard/reports/payouts": [~127,[2,25,32],[3]],
		"/(base)/dashboard/reports/payout/[payoutId]": [~126,[2,25,31],[3]],
		"/(base)/dashboard/reports/sales": [~128,[2,25],[3]],
		"/(base)/dashboard/settings": [~129,[2,25],[3]],
		"/(base)/dashboard/settings/fulfillment": [~130,[2,25],[3]],
		"/(base)/dashboard/settings/integrations": [~131,[2,25],[3]],
		"/(base)/dashboard/settings/teammate/[orgUserId]": [~132,[2,25],[3]],
		"/(base)/farmstand/[shopSlug]": [~133,[2],[3]],
		"/(base)/farmstand/[shopSlug]/product/[farmProductId]": [~134,[2],[3]],
		"/(base)/(public)/farm/[slug]": [56,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/checkout": [~59,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/onboarding": [~60,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/orders": [~62,[2,13,14,16],[3]],
		"/(base)/(public)/farm/[slug]/orders/cancelled": [~63,[2,13,14,16],[3]],
		"/(base)/(public)/farm/[slug]/order/[orderId]": [~61,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/product/[farmProductId]": [~64,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/(auth)/sign-in": [~57,[2,15],[3]],
		"/(base)/(public)/farm/[slug]/(auth)/verify": [~58,[2,15],[3]],
		"/(base)/graphql": [~135,[2],[3]],
		"/(base)/(app)/invoices": [~38,[2,4,6],[3]],
		"/(base)/links/[linkId]": [~136,[2],[3]],
		"/(base)/maintenance": [137,[2],[3]],
		"/(base)/(app)/orders": [~39,[2,4,7],[3]],
		"/(base)/(app)/orders/cancelled": [~41,[2,4,7],[3]],
		"/(base)/(app)/orders/csa": [~42,[2,4,7],[3]],
		"/(base)/(app)/orders/csa/[orderId]": [~44,[2,4,7],[3]],
		"/(base)/(app)/orders/csa/[fulfillmentDate]/[locationId]": [~43,[2,4],[3]],
		"/(base)/(app)/orders/[orderId]": [~40,[2,4,8],[3]],
		"/(base)/(privacy)/privacy": [~54,[2,12],[3]],
		"/sentry-example": [138],
		"/(base)/(public)/shop/[slug]": [65,[2,13,17],[3]],
		"/(base)/(public)/shop/[slug]/checkout": [~66,[2,13,17],[3]],
		"/(base)/(public)/shop/[slug]/product/[farmProductId]": [~67,[2,13,17],[3]],
		"/(base)/(auth)/sign-in": [~48,[2,10],[3]],
		"/(base)/(auth)/sign-out": [49,[2,10],[3]],
		"/(base)/(auth)/sign-up": [~50,[2,10],[3]],
		"/(base)/(auth)/sign-up/retail": [~51,[2,10],[3]],
		"/(base)/(auth)/sign-up/success": [52,[2,10],[3]],
		"/(base)/(app)/subscriptions": [~45,[2,4,9],[3]],
		"/(base)/(privacy)/tos": [~55,[2,12],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';