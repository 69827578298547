import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

if (import.meta.env.PUBLIC_VERCEL_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://1238204b133650e723d3b7500a52f350@o4507347799572480.ingest.us.sentry.io/4507347807961088',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [
			replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			})
		],
		environment: import.meta.env.PUBLIC_VERCEL_ENV,
		release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_REF,
		includeLocalVariables: true
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
